<template>
  <v-card tile elevation="0" class="my-auto pb-1 fill-height">
    <v-container v-if="molecularTest != null" class="pa-5">
      <!-- Photo row -->
      <!-- <v-row ref="parent" id="parent">
        <v-img
          :src="require('../../assets/img/no_image_strain.png')"
          aspect-ratio="1.0"
          contain
        ></v-img>
      </v-row> -->
      <v-row class="px-5">
        <v-col cols="6">
          <span class="font-weight-bold"
            >Análisis: {{ molecularIdentification.folioAnalysis }}
            <v-icon
              v-if="
                molecularIdentification.sampleType &&
                molecularIdentification.sampleType.includes('VEGETABLE')
              "
              class="pl-1"
              color="primary"
              small
              >mdi-sprout</v-icon
            ></span
          >
        </v-col>
      </v-row>
      <v-row class="py-0">
        <v-col cols="12" class="py-0 text-center">
          <p class="mb-0">Archivo de origen:</p>
          <p class="mb-0">
            <b>./{{ molecularTest.file }}.csv</b>
          </p>
        </v-col>
      </v-row>
      <v-row class="py-1">
        <v-divider></v-divider>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <p class="mb-0 pb-0">Microorganismos encontrados:</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-data-table
            :items="strains"
            :headers="headers"
            :item-class="itemRowBackground"
            hide-default-footer
            dense
          >
            <template v-slot:[`item.identification`]="{ item }">
              <b>{{ item.identification }}</b>
            </template>
            <template v-slot:[`item.concentration`]="{ item }">
              <span v-if="item.presence && molecularIdentification.sampleType && !molecularIdentification.sampleType.includes('VEGETABLE')">
                <v-edit-dialog
                  :return-value.sync="item.concentration"
                  @close="closeConcentration(item)"
                >
                  {{ parseInt(item.concentration).toExponential(2) }} UFC/g
                  <template v-slot:input>
                    <div class="mt-4 text-h6">Actualizar concentración</div>
                    <v-text-field
                      v-model.number="item.concentration"
                      single-line
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </span>
              <span v-else> --- </span>
            </template>
          </v-data-table>
        </v-col>
        <!-- <v-col cols="12" class="py-0">
          <small class="text--disabled"
            >Click sobre la concentración para su edición</small
          >
        </v-col> -->
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  components: {},
  data() {
    return {
      molecularTest: null,
      headers: [
        { text: "Nombre", sortable: false, value: "identification" },
        { text: "Tipo", sortable: false, value: "type" },
        { text: "Eficiencia", sortable: false, value: "efficiency" },
        { text: "Cq", sortable: false, value: "cycles" },
        { text: "UMC", sortable: false, value: "umc" },
      ],
      strains: [],
    };
  },
  props: {
    molecularIdentification: {
      type: Object,
      require: false,
    },
  },
  async created() {
    await this.getMolecularTestData();
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("molecularIdentification", [
      "getMolecularTestById",
      "updateStrainConcentration",
    ]),
    async getMolecularTestData() {
      try {
        let request = await this.getMolecularTestById(
          this.molecularIdentification._id
        );

        if (request.error) throw request;

        this.molecularTest = request.MOLECULAR_TEST;
        this.formatStrains();
      } catch (e) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Error al cargar el ensayo. Error: ${e}`,
          timeout: 2000,
        });
      }
    },
    formatStrains() {
      let strains = this.molecularTest.strains;

      this.strains = strains.map((strain) => {
        return {
          _id: strain._id,
          identification: strain.identification.name,
          type:
            strain.identification.moType == "fungi"
              ? "Hongo"
              : strain.identification.moType == "bacteria"
              ? "Bacteria"
              : "Nemátodo",
          cycles: strain.quantificationCycles,
          umc: strain.umc ? strain.umc : '-',
          efficiency : strain.efficiency ? strain.efficiency : '-',
          presence: strain.molecularPresence,
        };
      });
    },
    itemRowBackground: function (item) {
      return item.presence ? "custom-highlight-row" : "";
    },
    async closeConcentration(item) {
      try {
        let obj = {
          _id: item._id,
          concentration: item.concentration,
        };

        let request = await this.updateStrainConcentration(obj);

        if (request.error) throw request;

        this.SET_ALERT_TYPE({
          visible: true,
          type: "success",
          text: `Concentración actualizada correctamente`,
          timeout: 2000,
        });
      } catch (error) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Error al cargar el ensayo. Error: ${error}`,
          timeout: 2000,
        });
      }
    },
  },
  watch: {
    async molecularIdentification() {
      console.log(this.molecularIdentification);
      await this.getMolecularTestData();
    },
  },
};
</script>
