<template>
  <v-row class="fill-height">
    <v-col cols="12">
      <v-row class="height-content">
        <v-col cols="12" sm="4" md="3">
          <v-card class="fill-height">
            <v-card-text>
              <v-container>
                <v-row align="center" justify="center">
                  <v-col cols="12">
                    <v-select
                      :items="laboratories"
                      v-model="laboratory"
                      label="Laboratorio"
                      item-text="name"
                      return-object
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <small> Municipio:</small> <b> {{ laboratory.city }} </b>
                  </v-col>
                  <v-col cols="6">
                    <small> Estado: </small><b> {{ laboratory.state }} </b>
                  </v-col>
                  <v-col cols="12" md="10">
                    <packages-admin
                      :laboratory="laboratory"
                      @packages_updated="getPackagesLab"
                      @updateTable="refreshTable"
                    />
                  </v-col>

                  <v-col cols="12" class="text-center">
                    <v-combobox
                      v-model="cropsLaboratory"
                      :items="crops"
                      label="Cultivo/s"
                      item-text="es_text"
                      hide-details
                      multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @mousedown.prevent @click="toggle">
                          <v-list-item-content>
                            <v-list-item-title>
                              Seleccionar todos
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ index, item }">
                        <v-chip
                          v-if="index < 1"
                          color="primary accent-4"
                          dark
                          label
                          small
                        >
                          {{ item.es_text }}
                        </v-chip>

                        <small
                          v-else-if="index === 1"
                          class="grey--text text--darken-3"
                        >
                          +{{ cropsLaboratory.length - 1 }} Cultivo/s
                        </small>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-combobox
                      v-model="headquarter"
                      :items="headquarters"
                      label="Canal"
                      item-text="companyName"
                      hide-details
                    >
                    </v-combobox>
                  </v-col>
                  <v-col cols="8" class="my-auto">
                    <span>Núm. de reacciónes:</span>
                  </v-col>
                  <v-col cols="4" class="my-auto">
                    <v-text-field
                      v-model.number="reactions"
                      label="#"
                      type="number"
                      max="50"
                      min="1"
                      hide-controls
                      hide-details
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8" class="my-auto py-8">
                    <v-btn color="warning" block @click="updateLabData">
                      <v-icon left> mdi-content-save-alert-outline </v-icon
                      >Guardar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="8" md="9">
          <v-card class="fill-height">
            <v-card-title>
              Microorganismos asignados
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="downloadTable"
                >Descargar tabla</v-btn
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" class="text-right">
                    <v-data-table
                      :headers="headers"
                      :items="lab_mos"
                      hide-default-footer
                      dense
                      height="55vh"
                      :items-per-page="100"
                      no-data-text="No hay microorganismos asignados"
                    >
                      <template v-slot:item.edit="props">
                        <EditReaction
                          :laboratory="laboratory"
                          :reaction_data="props.item"
                          @reactionUpdated="loadData"
                          :packages="packages"
                        />
                      </template>
                      <template v-slot:item.crops="props">
                        <span v-if="props.item.crops.length == 0"
                          >Sin asignación</span
                        >
                        <template v-else-if="props.item.crops.length == 1">
                          <v-chip small color="secondary">{{
                            getEsCrop(props.item.crops[0])
                          }}</v-chip>
                        </template>
                        <template v-else-if="props.item.crops.length == 2">
                          <v-chip small color="secondary">{{
                            getEsCrop(props.item.crops[0])
                          }}</v-chip>
                          + {{ props.item.crops.length - 1 }} Cultivo
                        </template>
                        <template v-else>
                          <v-chip small color="secondary">{{
                            getEsCrop(props.item.crops[0])
                          }}</v-chip>
                          + {{ props.item.crops.length - 1 }} Cultivo/s
                        </template>
                      </template>
                      <template v-slot:item.fam="props">
                        <span v-if="!props.item.fam">ND</span>
                        <span
                          v-else
                          :class="
                            props.item.fam.isPathogen
                              ? 'warning--text'
                              : 'primary--text'
                          "
                          >{{
                            props.item.fam.packages &&
                            props.item.fam.packages.length > 0
                              ? "*"
                              : ""
                          }}{{ props.item.fam.name }}</span
                        >
                      </template>
                      <template v-slot:item.hex="props">
                        <span v-if="!props.item.hex">ND</span>
                        <span
                          v-else
                          :class="
                            props.item.hex.isPathogen
                              ? 'warning--text'
                              : 'primary--text'
                          "
                          >{{
                            props.item.hex.packages &&
                            props.item.hex.packages.length > 0
                              ? "*"
                              : ""
                          }}{{ props.item.hex.name }}</span
                        >
                      </template>
                      <template v-slot:item.cy5="props">
                        <span v-if="!props.item.cy5">ND</span>
                        <span
                          v-else
                          :class="
                            props.item.cy5.isPathogen
                              ? 'warning--text'
                              : 'primary--text'
                          "
                          >{{
                            props.item.cy5.packages &&
                            props.item.cy5.packages.length > 0
                              ? "*"
                              : ""
                          }}{{ props.item.cy5.name }}</span
                        >
                      </template>
                    </v-data-table>
                    <small>* Indica que el MM fué asignado a un paquete</small>
                    <v-snackbar
                      v-model="snack"
                      :timeout="3000"
                      :color="snackColor"
                    >
                      {{ snackText }}

                      <template v-slot:action="{ attrs }">
                        <v-btn v-bind="attrs" text @click="snack = false">
                          Close
                        </v-btn>
                      </template>
                    </v-snackbar>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import cropsJson from "@/data/crops.json";

import EditReaction from "@/components/cmda_admin/modals/EditReaction";
import PackagesAdmin from "@/components/cmda_admin/modals/PackagesAdmin";
import xlsx from "json-as-xlsx";

export default {
  components: {
    EditReaction,
    PackagesAdmin,
  },
  data() {
    return {
      laboratory: {
        name: "-",
        city: "-",
      },
      reactions: 1,
      crops: cropsJson,
      cropsLaboratory: [],
      headquarter: null,
      headquarters: [],
      snack: false,
      snackColor: "",
      snackText: "",
      max25chars: (v) => v.length <= 25 || "Input too long!",
      pagination: {},
      headers: [
        {
          text: "Reacción",
          align: "start",
          sortable: false,
          value: "reaction",
        },
        { text: "Cultivo/s", value: "crops" },
        { text: "FAM", value: "fam" },
        { text: "HEX", value: "hex" },
        { text: "CY5", value: "cy5" },
        { text: "Edición", value: "edit" },
      ],
      lab_mos: [],
      user: JSON.parse(localStorage.getItem("user")),
      packages: [],
      cardHover: false,
    };
  },
  computed: {
    ...mapState("laboratory", ["laboratories"]),
    selectedAllCrops() {
      return this.cropsLaboratory.length === this.crops.length;
    },
  },
  methods: {
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    ...mapActions("laboratory", [
      "getLaboratories",
      "updateLaboratory",
      "getPackages",
    ]),
    ...mapActions("organization", ["getOrganizationsHeadquarters"]),
    async loadData() {
      let req = null;
      if (this.user.laboratory.name.includes("CMDA")) {
        req = await this.getLaboratories({ type: "MOLECULAR" });
      } else {
        req = await this.getLaboratories({ type: "MICROBIOLOGICAL/MOLECULAR" });
      }

      if (!req.error) {
        if (this.laboratory._id) {
          const lab_id_selected = this.laboratory._id.toString();
          this.laboratory = this.laboratories.find(
            (lab) => lab._id.toString() === lab_id_selected
          );
        } else {
          this.laboratory = this.laboratories[0];
        }

        this.formatLab();
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectedAllCrops) {
          this.cropsLaboratory = [];
        } else {
          this.cropsLaboratory = this.crops.slice();
        }
      });
    },
    async updateLabData() {
      try {
        if (this.cropsLaboratory.length > 0) {
          if (this.reactions > 0 && this.reactions < 51) {
            const lab_body_req = {
              _id: this.laboratory._id,
              headquarter: this.headquarter ? this.headquarter._id : null,
              cmda_properties: {
                reactions: this.reactions,
                crops: this.cropsLaboratory.map((cropData) => cropData.en_text),
                mos: this.formatMos(),
              },
            };

            const req = await this.updateLaboratory(lab_body_req);

            if (req.error) {
              throw Error(req);
            }

            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: "Laboratorio actualizado correctamente",
              timeout: 4000,
            });

            await this.loadData();
          } else {
            this.SET_ALERT_TYPE({
              visible: true,
              type: "error",
              text: "Debes agregar un número válido de reacciónes",
              timeout: 4000,
            });
          }
        } else {
          this.SET_ALERT_TYPE({
            visible: true,
            type: "error",
            text: "Debes agregar un cultivo o más",
            timeout: 4000,
          });
        }
      } catch (error) {
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 5000,
        });
      }
    },
    formatMos() {
      let mos = [];
      const labLetter = this.laboratory.name.substring(5, 6);

      if (this.laboratory.cmda_properties) {
        const lastReactions = this.laboratory.cmda_properties.reactions;

        if (this.reactions > lastReactions) {
          const diff = this.reactions - lastReactions;

          mos = this.laboratory.cmda_properties.mos;

          for (let i = 0; i < diff; i++) {
            const index = lastReactions + (i + 1);

            mos.push({
              reaction: `R${index}-${labLetter}`,
              crops: [],
              fam: null,
              famPathogen: false,
              hex: null,
              hexPathogen: false,
              cy5: null,
              cy5Pathogen: false,
            });
          }
        } else {
          const diff = lastReactions - this.reactions;
          let mosAux = [...this.laboratory.cmda_properties.mos];

          mosAux.splice(mosAux.length - diff, diff);
          mos = mosAux;
        }
      } else {
        for (let i = 0; i < this.reactions; i++) {
          mos.push({
            reaction: `R${i + 1}-${labLetter}`,
            crops: [],
            fam: null,
            famPathogen: false,
            hex: null,
            hexPathogen: false,
            cy5: null,
            cy5Pathogen: false,
          });
        }
      }

      return mos;
    },
    formatLab() {
      if (this.laboratory && this.laboratory.cmda_properties) {
        this.reactions = this.laboratory.cmda_properties.reactions;
        this.cropsLaboratory = this.laboratory.cmda_properties.crops.map(
          (crop) => this.crops.find((cropJson) => cropJson.en_text === crop)
        );
        this.lab_mos = this.laboratory.cmda_properties.mos;
      } else {
        this.reactions = 1;
        this.cropsLaboratory = [];
        this.lab_mos = [];
      }

      this.headquarter = this.laboratory.headquarter
        ? this.headquarters.find((h) => h._id === this.laboratory.headquarter)
        : null;
    },
    getEsCrop(en_crop) {
      return this.crops.find((c) => c.en_text === en_crop).es_text;
    },
    downloadTable() {
      const actually_date = new Date();

      var columns = [
        { label: "Reacción", value: "reaction" },
        { label: "Cultivo(s)", value: "crops" },
        { label: "FAM", value: "fam" },
        { label: "HEX", value: "hex" },
        { label: "CY5", value: "cy5" },
      ];

      var content = this.laboratory.cmda_properties.mos.map((data) => {
        const crops_spanish = data.crops.map((crop) => this.getEsCrop(crop));

        return {
          reaction: data.reaction,
          crops: data.crops.length > 0 ? crops_spanish.join(", ") : "-",
          fam: data.fam ? data.fam.name : "-",
          hex: data.hex ? data.hex.name : "-",
          cy5: data.cy5 ? data.cy5.name : "-",
        };
      });

      var settings = {
        fileName: `${this.laboratory.name} ${actually_date
          .toLocaleDateString()
          .toString()
          .replaceAll("/", "-")}`,
      };

      xlsx(
        [
          {
            sheet: "Hoja 1",
            columns,
            content,
          },
        ],
        settings
      );
    },
    async getPackagesLab(packages = null) {
      try {
        if (packages == null) {
          const req = await this.getPackages({
            laboratory: this.laboratory._id,
          });

          if (req.error) throw req;

          this.packages = req.packages;
        } else {
          this.packages = packages;
        }
      } catch (error) {
        console.error(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
    },
    async getHeadquarters() {
      try {
        const req = await this.getOrganizationsHeadquarters();

        if (req.error) throw req;

        this.headquarters = req.headquarters;
      } catch (error) {
        console.error(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: error.message,
          timeout: 4000,
        });
      }
    },
    async refreshTable() {
      await this.loadData();
    },
  },
  async created() {
    await this.getHeadquarters();
    await this.loadData();
    await this.getPackagesLab();
  },
  watch: {
    async laboratory() {
      this.formatLab();
      await this.getPackagesLab();
    },
  },
};
</script>

<style>
.height-content {
  height: 92%;
}
.custom-card {
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.custom-elevation {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  transform: scale(
    1.08
  ); /* Ajusta la escala para que la tarjeta sea más grande al pasar el cursor */
}

.custom-elevation-small {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transform: scale(1); /* La tarjeta vuelve a su tamaño original */
}

.custom-icon {
  background: linear-gradient(45deg, #ff8f1c, #04c105); /* Degradado de color */
  -webkit-background-clip: text; /* Clip para que el degradado se aplique al texto */
  -webkit-text-fill-color: transparent; /* Oculta el color del texto */
}
</style>
