<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" class="text-start">
        <v-btn color="primary" @click="goBack">
          <v-icon> mdi-arrow-left-thick </v-icon>
          Atras
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-container>
          <v-form lazy-validation ref="form">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="Ingresa ID marcador"
                  clearable
                  v-model="form.molecularMarkerID"
                  :rules="validate('ID')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  dense
                  outlined
                  label="Selecciona microorganismo"
                  clearable
                  :search-input.sync="searchIdentification"
                  :items="identifications"
                  item-text="name"
                  :loading="identificationsLoading"
                  v-model="form.identification"
                  return-object
                  :rules="validate('microorganismo')"
                  :disabled="disabledUpdate"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-switch
                  dense
                  outlined
                  label="Patógeno"
                  clearable
                  v-model="form.isPathogen"
                  :disabled="disabledUpdate"
                >
                </v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="body-2 font-weight-bold">Rango de Cq's</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="CQ mínimo"
                  type="number"
                  clearable
                  v-model="form.cqMin"
                  :rules="validate('CQ mínimo')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  label="CQ maximo"
                  type="number"
                  clearable
                  v-model="form.cqMax"
                  :rules="validate('CQ maximo')"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-end">
              <v-col cols="2">
                <v-btn color="primary" block @click="saveData">
                  <v-icon>mdi-content-save</v-icon>
                  Guardar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
// validations
import { requiredValidation } from "@/helpers/validations.js";
export default {
  data: () => ({
    searchIdentification: null,
    identificationsLoading: false,
    identifications: [],
    form: {
      identification: null,
      molecularMarkerID: null,
      isPathogen: false,
      cqMax: null,
      cqMin: null,
    },
    disabledUpdate: false,
  }),
  computed: {
    ...mapState("laboratory", ["molecularMarkerToUpdate"]),
  },
  async mounted() {
    if (this.molecularMarkerToUpdate) {
      this.disabledUpdate = true;
      await this.mapContent();
    }
  },
  methods: {
    ...mapActions("identification", ["searchIdentifications"]),
    ...mapActions("laboratory", ["createMarker", "updateMarker"]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async saveData() {
      try {
        if (this.$refs.form.validate()) {
          let payload = {
            molecularMarkerID: this.form.molecularMarkerID,
            microorganism_meta: {
              _id: this.form.identification._id,
              name: this.form.identification.name,
              moType: this.form.identification.moType,
            },
            markerType: "MO",
            isPathogen: this.form.isPathogen,
            cqMax: this.form.cqMax,
            cqMin: this.form.cqMin,
            version: 1,
          };
          if (this.molecularMarkerToUpdate) {
            //update marker
            delete payload.version;
            delete payload.microorganism_meta;
            delete payload.markerType;

            let request = {
              body: payload,
              params: { markerId: this.molecularMarkerToUpdate._id },
            };
            await this.updateMarker(request);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: `Marcador actualizado con exito`,
              timeout: 5000,
            });
            this.goBack();
          } else {
            //create new one
            await this.createMarker(payload);
            this.SET_ALERT_TYPE({
              visible: true,
              type: "success",
              text: `Marcador creado con exito`,
              timeout: 5000,
            });
            this.goBack();
          }
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Algo salió mal. Error: ${error.message}`,
          timeout: 5000,
        });
      }
    },
    goBack() {
      this.$emit("back_update");
    },
    validate(name) {
      return requiredValidation(name);
    },
    async mapContent() {
      await this.getIdentifications(
        this.molecularMarkerToUpdate.microorganism_meta.name
      );
      this.form.identification = this.identifications.find(
        (element) =>
          element._id == this.molecularMarkerToUpdate.microorganism_meta._id
      );
      this.form.molecularMarkerID =
        this.molecularMarkerToUpdate.molecularMarkerID;
      this.form.isPathogen = this.molecularMarkerToUpdate.isPathogen;
      this.form.cqMin = this.molecularMarkerToUpdate.cqMin;
      this.form.cqMax = this.molecularMarkerToUpdate.cqMax;
    },
    async getIdentifications(value) {
      if (value) {
        try {
          this.identificationsLoading = true;
          const response = await this.searchIdentifications({
            name: value,
          });
          if (response.error) throw response;
          this.identifications = response.IDENTIFICATIONS;
        } catch (error) {
          console.log(error);
        }
        this.identificationsLoading = false;
      } else {
        this.identifications = [];
      }
    },
  },
  watch: {
    async searchIdentification(value) {
      await this.getIdentifications(value);
    },
  },
};
</script>
