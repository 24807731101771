<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="900"
      persistent
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn small depressed block color="primary" v-bind="attrs" v-on="on">
          <!-- <v-icon left> mdi-molecule </v-icon> -->
          🧬 Identificación Molecular
        </v-btn>
      </template>
      <template v-slot:default>
        <v-card>
          <v-toolbar color="primary" short dark
            >Identificación Molecular</v-toolbar
          >
          <v-card-text>
            <v-container>
              <v-row class="pt-3">
                <span
                  >Seleccionar uno o más archivos para procesar, visualizar y
                  guardar los MOs identificados.
                  <small>(Límite de sensibilidad 15 - 34)</small></span
                >
              </v-row>
              <v-row
                v-if="
                  permissions.find((per) => per == 'lab_results_ignore_culture')
                "
              >
                <v-col>
                  <v-switch
                    label="Resultados sin considerar el cultivo"
                    v-model="ignoreCulture"
                    dense
                  >
                  </v-switch>
                </v-col>
              </v-row>
              <v-row class="pb-3" align="center" justify="center">
                <v-col cols="12">
                  <v-file-input
                    v-model="files"
                    color="secondary"
                    label="Documento/s (.csv)"
                    multiple
                    placeholder="Selecióna los archivos"
                    prepend-icon="mdi-file-document-multiple-outline"
                    outlined
                    accept=".csv"
                    :loading="loadingSave"
                    @change="filesChangedEvent"
                    :disabled="loadingData || loadingSave"
                    hide-details
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 3"
                        color="secondary"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 3"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 3 }} Archivo(s)
                      </span>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <p v-if="molecularTests.length > 0" class="pt-2">Resultados :</p>
              <v-row v-for="(data, index) in molecularTests" :key="index">
                <v-col cols="10">
                  <v-container>
                    <v-row class="py-0">
                      <span class="font-weight-bold warning--text"
                        >Archivo: {{ data.file }}</span
                      >
                    </v-row>
                    <v-row class="py-0" v-if="data.mosLowEfficiency > 0">
                      <small
                        v-if="data.mosLowEfficiency > 1"
                        class="font-weight-bold red--text"
                        >⚠ Se detectaron {{ data.mosLowEfficiency }} resultados
                        con baja eficiencia de detección</small
                      >
                      <small v-else class="font-weight-bold red--text"
                        >⚠ Se detectó {{ data.mosLowEfficiency }} resultado con
                        baja eficiencia de detección</small
                      >
                    </v-row>
                    <v-row class="py-0" v-if="data.mosCqHigh > 0">
                      <small
                        v-if="data.mosCqHigh > 1"
                        class="font-weight-bold red--text"
                        >⚠ Se detectaron {{ data.mosCqHigh }} resultados con el
                        ciclo alto de deteción (cq)</small
                      >
                      <small v-else class="font-weight-bold red--text"
                        >⚠ Se detectó {{ data.mosCqHigh }} resultado con el
                        ciclo alto de deteción (cq)</small
                      >
                    </v-row>
                    <v-row class="py-0" v-if="data.errorMos > 0">
                      <small
                        v-if="data.errorMos > 1"
                        class="font-weight-bold red--text"
                        >⚠ Se detectaron {{ data.errorMos }} resultados con
                        errores</small
                      >
                      <small v-else class="font-weight-bold red--text"
                        >⚠ Se detectó {{ data.errorMos }} resultado con un
                        error</small
                      >
                    </v-row>
                    <v-row
                      class="py-0"
                      v-if="data.mosLowEfficiency > 0 || data.mosCqHigh > 0"
                    >
                      <small
                        >Los resultados con ciclo alto de detección (cq) y/o
                        baja eficiencia de detección se interpretarán como
                        <b>"No detectados"</b></small
                      >
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    color="secondary"
                    small
                    block
                    @click="removeFile(index)"
                  >
                    <v-icon left> mdi-delete </v-icon>
                    Descartar
                  </v-btn>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-data-table
                    v-model="molecularTestsSelected"
                    :headers="tableHeaders"
                    :items="data.tests"
                    :items-per-page="-1"
                    no-data-text="No se detectaron microogranismos"
                    height="41vh"
                    checkbox-color="secondary"
                    item-key="_id"
                    fixed-header
                    hide-default-footer
                    show-select
                  >
                    <template v-slot:[`item.analysis`]="{ item }">
                      <span v-if="!item.error">{{ item.analysis }}</span>
                      <v-tooltip v-else bottom color="error">
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            class="warning--text"
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ item.analysis }}
                          </span>
                        </template>
                        <span>{{ item.errorMessage }}</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:[`item.sample`]="{ item }">
                      {{ item.sample }}
                      <v-icon
                        v-if="item.isFromVegetalSample"
                        class="pl-1"
                        color="primary"
                        small
                        >mdi-sprout</v-icon
                      >
                    </template>
                    <template v-slot:[`item.crop`]="{ item }">
                      <span>{{ getCropSpanishName(item.crop) }}</span>
                    </template>
                    <template v-slot:[`item.efficiency`]="{ item }">
                      <template v-if="!item.efficiency">- - -</template>
                      <template v-else>
                        <b v-if="item.efficiency < 0.5" class="warning--text"
                          >⚠ {{ item.efficiency }}</b
                        >
                        <span v-else>{{ item.efficiency }}</span>
                      </template>
                    </template>
                    <template v-slot:[`item.concentration`]="{ item }">
                      <span
                        v-if="item.result == '-1' || item.isFromVegetalSample"
                        >---</span
                      >
                      <v-edit-dialog
                        v-else
                        :return-value.sync="item.concentration"
                        @close="closeConcentration(item)"
                      >
                        {{ parseInt(item.concentration).toExponential(2) }}
                        UFC/g
                        <template v-slot:input>
                          <div class="mt-4 text-h6">
                            Actualizar concentración
                          </div>
                          <v-text-field
                            v-model.number="item.concentration"
                            single-line
                          ></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                    <template v-slot:[`item.index`]="{ item }">
                      <span v-if="item.umc">{{ item.umc }}</span>
                      <span v-else>- - -</span>
                    </template>
                    <template v-slot:[`item.result`]="{ item }">
                      <span v-if="item.result == '-1'">No detectado</span>
                      <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-if="
                              parseFloat(item.result) >= item.cqMax ||
                              parseFloat(item.result) < item.cqMin
                            "
                            v-bind="attrs"
                            v-on="on"
                            class="font-weight-bold warning--text"
                            >{{ item.result }}</span
                          >
                          <span v-else>{{ item.result }}</span>
                        </template>
                        <span>No detectado</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:[`item.mo`]="{ item }">
                      <template v-if="item.threat != null">
                        <span v-if="item.threat" class="warning--text">{{
                          item.mo
                        }}</span>
                        <span v-else class="primary--text">{{ item.mo }}</span>
                      </template>
                      <template v-else>
                        <span>{{ item.mo }}</span>
                      </template>
                    </template>
                  </v-data-table>
                  <v-row align="center" justify="center">
                    <v-col cols="12" class="text-right">
                      <small class="pr-5"
                        ><v-icon color="primary" small>mdi-sprout</v-icon>
                        Muestra Vegetal</small
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <!-- <ControlPanel /> -->
            <v-btn text @click="cancelIdentification" :disabled="loadingSave"
              >Cancelar</v-btn
            >
            <v-btn
              text
              color="primary"
              :loading="loadingSave"
              :disabled="molecularTestsSelected.length == 0"
              @click="addMolecularData"
              >Agregar ({{
                molecularTestsSelected.length + molecularTestsDescarted.length
              }})</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog v-model="loadingData" persistent width="300">
      <v-card color="secondary" dark>
        <v-card-text>
          Procesando archivo/s
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loadingErrorData" scrollable persistent width="770">
      <v-card>
        <v-toolbar color="warning" short dark
          >Errores ({{ molecularTestsWrong.length }})</v-toolbar
        >
        <v-card-text>
          <v-container fluid>
            <v-row align="center" justify="center" class="pt-5">
              <v-data-table
                height="70vh"
                fixed-header
                hide-default-footer
                :items-per-page="-1"
                :headers="wrongTableHeaders"
                :items="molecularTestsWrong"
              >
                <template v-slot:[`item.concentration`]="{ item }">
                  <span v-if="item.cycles == '-1'">---</span>
                  <span v-else
                    >{{
                      Number.parseFloat(item.concentration).toExponential(2)
                    }}
                    UFC/g</span
                  >
                </template>
                <template v-slot:[`item.cycles`]="{ item }">
                  <span v-if="item.cycles == '-1'">Sin presencia</span>
                  <span v-else>{{ item.cycles }}</span>
                </template>
              </v-data-table>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="closeWrongTests">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ControlPanel from "./ControlPanel.vue";
export default {
  data() {
    return {
      dialog: false,
      files: [],
      loadingData: false,
      loadingSave: false,
      loadingErrorData: false,
      molecularTests: [],
      molecularTestsDescarted: [],
      molecularTestsSelected: [],
      tableHeaders: [
        { text: "Analisis", value: "analysis" },
        { text: "Muestra", value: "sample" },
        { text: "Cultivo", value: "crop" },
        { text: "Identificación", value: "mo" },
        { text: "Eficiencia", value: "efficiency" },
        { text: "Resultado (x̄)", value: "result" },
        { text: "UMC", value: "umc" },
      ],
      wrongTableHeaders: [
        { text: "Analisis", value: "analysis" },
        { text: "Muestra", value: "sample" },
        { text: "Identificación", value: "mo" },
        { text: "Eficiencia", value: "efficiency" },
        { text: "Resultado (x̄)", value: "cycles" },
        { text: "UMC", value: "umc" },
        { text: "Causa", value: "message" },
      ],
      molecularTestsWrong: [],
      user: JSON.parse(localStorage.getItem("user")),
      ignoreCulture: false,
    };
  },
  methods: {
    ...mapActions("molecularIdentification", [
      "uploadFile",
      "addIdentifications",
      "saveReportResult",
    ]),
    ...mapMutations("alerts", ["SET_ALERT_TYPE"]),
    async filesChangedEvent() {
      try {
        if (this.files.length > 0) {
          this.loadingData = true;
          await this.processFiles();
          this.loadingData = false;
        } else {
          this.molecularTests = [];
          this.molecularTestsSelected = [];
          this.molecularTestsDescarted = [];
        }
      } catch (error) {
        console.log(error);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: `Error al procesar resultados: ${error.message}`,
          timeout: 5500,
        });

        this.loadingData = false;

        this.molecularTests = [];
        this.molecularTestsSelected = [];
        this.molecularTestsDescarted = [];
      }
    },
    async processFiles() {
      let molecularData = [];
      for (const file of this.files) {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("ignoreCulture", this.ignoreCulture);
        let request = await this.uploadFile(formData);
        if (request.error) {
          throw request;
        } else {
          // console.log(request.molecularData);
          let { data, mosLowEfficiency, mosCqHigh, errorMos } =
            this.formatDataTableData(request.molecularData);
          molecularData.push({
            file: request.molecularData.fileName,
            tests: data,
            mosLowEfficiency,
            mosCqHigh,
            errorMos,
          });
        }
      }
      this.molecularTests = molecularData;
    },
    formatDataTableData(obj) {
      let allMolecularData = [];
      let mosLowEfficiency = 0;
      let mosCqHigh = 0;
      let errorMos = 0;
      for (const test of obj.data) {
        let testData = {
          analysis: "",
          sample: "",
          mo: "",
          result: "",
          isSelectable: true,
          isFromVegetalSample: false,
          efficiency: 0,
        };
        testData.fileName = obj.fileName;
        testData.mo = test.mo;
        let index = 0;
        for (const molecularTest of test.tests) {
          if (molecularTest.sample != "M") {
            testData._id = `${index}${testData.mo}-${molecularTest.analysis}-${molecularTest.sample}-${molecularTest.result}`;
            testData.analysis = molecularTest.analysis;
            testData.sample = molecularTest.sample;
            testData.result = molecularTest.result;
            testData.umc = molecularTest.umc
              ? molecularTest.umc.toFixed(2)
              : null;
            testData.isFromVegetalSample = molecularTest.isFromVegetalSample;
            testData.efficiency = molecularTest.efficiency;
            testData.crop = molecularTest.crop;
            testData.threat =
              molecularTest.threat != undefined ? molecularTest.threat : null;
            testData.errorMessage = "";
            testData.error = false;
            testData.cqMax = molecularTest.cqMax;
            testData.cqMin = molecularTest.cqMin;
            // if (
            //   molecularTest.analysis.includes("Control") ||
            //   parseFloat(molecularTest.result) >= 35
            //   // || molecularTest.efficiency < 0.5
            // ) {
            //   // testData.isSelectable = false;
            //   if (
            //     !molecularTest.analysis.includes("Control") &&
            //     parseFloat(molecularTest.result) >= 35
            //   ) {
            //     // this.molecularTestsDescarted.push({ ...testData });
            //   }
            // } else {
            //   if (
            //     !molecularTest.efficiency ||
            //     molecularTest.efficiency >= 0.5
            //   ) {
            //     this.molecularTestsSelected.push({ ...testData });
            //   }
            //   testData.isSelectable = true;
            // }
            if (
              !molecularTest.analysis.includes("Control")
              // || molecularTest.efficiency < 0.5
            ) {
              if (!molecularTest.error) {
                this.molecularTestsSelected.push({ ...testData });
              }

              if (
                parseFloat(molecularTest.result) != -1 &&
                (parseFloat(molecularTest.result) >= molecularTest.cqMax ||
                  parseFloat(molecularTest.result) < molecularTest.cqMin)
              ) {
                mosCqHigh++;
              }

              if (molecularTest.error) {
                testData.isSelectable = false;
                testData.errorMessage = molecularTest.errorMessage;
                testData.error = true;
                errorMos++;
              } else {
                testData.isSelectable = true;
              }
            } else {
              testData.isSelectable = false;
            }

            if (molecularTest.efficiency && molecularTest.efficiency < 0.5) {
              mosLowEfficiency++;
            }
            allMolecularData.push({ ...testData });
          }

          index++;
        }
      }
      return { data: allMolecularData, mosLowEfficiency, mosCqHigh, errorMos };
    },
    removeFile(index) {
      let file = this.molecularTests[index].file;
      let molecularTestsAux = this.molecularTestsSelected.filter(
        (test) => test.fileName != file
      );
      this.molecularTestsSelected = molecularTestsAux;
      this.molecularTests.splice(index, 1);
      this.files.splice(index, 1);
    },
    cancelIdentification() {
      this.files = [];
      this.molecularTests = [];
      this.molecularTestsSelected = [];
      this.molecularTestsDescarted = [];
      this.dialog = false;
    },
    async addMolecularData() {
      this.loadingSave = true;
      try {
        let tests = this.molecularTestsSelected.map((test) => {
          if (
            parseFloat(test.result) >= test.cqMax ||
            parseFloat(test.result) < test.cqMin ||
            (test.efficiency && test.efficiency < 0.5)
          ) {
            return {
              isFromVegetalSample: test.isFromVegetalSample,
              file: test.fileName,
              analysis: test.analysis,
              sample: test.sample,
              mo: test.mo,
              efficiency: test.efficiency ? test.efficiency : 0,
              cycles: test.result,
              umc: test.umc,
              threat: test.threat,
              cqMax: test.cqMax,
              cqMin: test.cqMin,
            };
          } else {
            return {
              isFromVegetalSample: test.isFromVegetalSample,
              file: test.fileName,
              analysis: test.analysis,
              sample: test.sample,
              mo: test.mo,
              efficiency: test.efficiency,
              cycles: test.result,
              umc: test.umc,
              threat: test.threat,
              cqMax: test.cqMax,
              cqMin: test.cqMin,
            };
          }
        });
        // tests = tests.concat(
        //   this.molecularTestsDescarted.map((test) => {
        //     return {
        //       isFromVegetalSample: test.isFromVegetalSample,
        //       file: test.fileName,
        //       analysis: test.analysis,
        //       sample: test.sample,
        //       mo: test.mo,
        //       cycles: "-1",
        //       concentration: "0",
        //       threat : test.threat
        //     };
        //   })
        // );
        // console.log(tests)
        const testsByFile = this.groupBy(tests, "file");
        const testsByFileFormated = this.objectToArrayMap(testsByFile);
        let index = 0;
        for (const fileData of testsByFileFormated) {
          let testsFormated = fileData[1];

          let request = await this.addIdentifications({
            testsFormated,
            user: this.user._id,
          });

          var file = this.files[index];

          const fileInfo = {
            fileName: this.user.laboratory
              ? `${this.user.laboratory.name}/${file.name}`
              : file.name,
          };

          const json = JSON.stringify(fileInfo);
          const blobJSON = new Blob([json], {
            type: "application/json",
          });

          const data = new FormData();
          data.append("json", blobJSON);
          data.append("file", file);

          const docReq = await this.saveReportResult(data);

          if (request.error) {
            throw request;
          }

          if (docReq.error) {
            throw docReq;
          }
          if (request.wrongMolecularTests.length > 0) {
            let moWrong = {
              analysis: "",
              sample: "",
              mo: "",
              cycles: "",
              umc: "",
              message: "",
            };
            let wrongs = [];
            for (const analysis of request.wrongMolecularTests) {
              for (const moData of analysis[1]) {
                moWrong = {
                  analysis: moData.analysis,
                  sample: moData.sample,
                  mo: moData.mo,
                  cycles: moData.cycles,
                  umc: moData.umc,
                  message: analysis.message,
                };
                wrongs.push(moWrong);
              }
            }
            this.molecularTestsWrong = wrongs;
            this.loadingErrorData = true;
            this.SET_ALERT_TYPE({
              visible: true,
              type: "info",
              text: "Algúnas identificaciónes no se pudieron guardar.",
              timeout: 3500,
            });
          }

          index++;
        }
        this.$emit("molecularIdentificationsAdded");
        this.cancelIdentification();
      } catch (e) {
        console.log(e);
        this.SET_ALERT_TYPE({
          visible: true,
          type: "error",
          text: e.message,
          timeout: 3500,
        });
      }
      this.loadingSave = false;
    },
    closeWrongTests() {
      this.molecularTestsWrong = [];
      this.loadingErrorData = false;
    },
    groupBy(objectArray, property) {
      return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        // Add object to list for given key's value
        acc[key].push(obj);
        return acc;
      }, {});
    },
    objectToArrayMap(obj) {
      const map = new Map(Object.entries(obj));
      return Array.from(map);
    },
    closeConcentration(test) {
      let testToUpdated = this.molecularTestsSelected.find(
        (element) => element._id == test._id
      );
      testToUpdated.concentration = test.concentration;
    },
    getCropSpanishName(cropEnglishName) {
      const crops = [
        { english: "olive", spanish: "Aceituna" },
        { english: "chard", spanish: "Acelga" },
        { english: "achiote", spanish: "Achiote" },
        { english: "agave", spanish: "Agave" },
        { english: "avocado", spanish: "Aguacate" },
        { english: "garlic", spanish: "Ajo" },
        { english: "sesame", spanish: "Ajonjolí" },
        { english: "basil", spanish: "Albahaca" },
        { english: "artichoke", spanish: "Alcachofa" },
        { english: "alcatraz", spanish: "Alcatraz" },
        { english: "shrunkenAlfalfa", spanish: "Alfalfa achicalada" },
        { english: "greenAlfalfa", spanish: "Alfalfa verde" },
        { english: "cotton", spanish: "Algodón" },
        { english: "almond", spanish: "Almendro" },
        { english: "birdseed", spanish: "Alpiste" },
        { english: "amaranth", spanish: "Amaranto" },
        { english: "anise", spanish: "Anís" },
        { english: "celery", spanish: "Apio" },
        { english: "blueberry", spanish: "Arándano" },
        { english: "myrtle", spanish: "Arrayán" },
        { english: "rice", spanish: "Arroz" },
        { english: "greenForageOats", spanish: "Avena forrajera en verde" },
        { english: "oatGrain", spanish: "Avena grano" },
        { english: "bamboo", spanish: "Bambú" },
        { english: "eggplant", spanish: "Berenjena" },
        { english: "beetroot", spanish: "Betabel" },
        { english: "broccoli", spanish: "Brocoli" },
        { english: "peanut", spanish: "Cacahuate" },
        { english: "cocoa", spanish: "Cacao" },
        { english: "cherryCoffee", spanish: "Café cereza" },
        { english: "pumpkin", spanish: "Calabaza" },
        { english: "sweetPotato", spanish: "Camote" },
        { english: "sugarCane", spanish: "Caña de azúcar" },
        { english: "canola", spanish: "Canola" },
        { english: "carambolo", spanish: "Carambolo" },
        { english: "safflower", spanish: "Cártamo" },
        { english: "barley", spanish: "Cebada" },
        { english: "greenForageBarley", spanish: "Cebada forrajera en verde" },
        { english: "onion", spanish: "Cebolla" },
        { english: "chives", spanish: "Cebollín" },
        { english: "ryeGrain", spanish: "Centeno grano" },
        { english: "cherry", spanish: "Cereza" },
        { english: "chabacano", spanish: "Chabacano" },
        { english: "pea", spanish: "Chícharo" },
        { english: "squash", spanish: "Chayote" },
        { english: "chia", spanish: "Chía" },
        { english: "chilacayote", spanish: "Chilacayote" },
        { english: "chillyPepper", spanish: "Chile / Pimiento" },
        { english: "custardApple", spanish: "Chirimoya" },
        { english: "coriander", spanish: "Cilantro" },
        { english: "plum", spanish: "Ciruela" },
        { english: "coconutFruit", spanish: "Coco fruta" },
        { english: "cabbage", spanish: "Col (repollo)" },
        { english: "brusselsSprouts", spanish: "Col de Bruselas" },
        { english: "cauliflower", spanish: "Coliflor" },
        { english: "cumin", spanish: "Comino" },
        { english: "chrysanthemum", spanish: "Crisantemo (planta)" },
        { english: "peach", spanish: "Durazno" },
        { english: "date", spanish: "Dátil" },
        { english: "greenBean", spanish: "Ejote" },
        { english: "cornCob", spanish: "Elote" },
        { english: "dill", spanish: "Eneldo" },
        { english: "epazote", spanish: "Epazote" },
        { english: "asparagus", spanish: "Espárrago" },
        { english: "spicesAndMedicinals", spanish: "Especias y medicinales" },
        { english: "spinach", spanish: "Espinaca" },
        { english: "raspberry", spanish: "Frambuesa" },
        { english: "strawberry", spanish: "Fresa" },
        { english: "bean", spanish: "Frijol" },
        { english: "chickpea", spanish: "Garbanzo" },
        { english: "sunflower", spanish: "Girasol" },
        { english: "pomegranate", spanish: "Granada" },
        { english: "soursop", spanish: "Guanábana" },
        { english: "guava", spanish: "Guayaba" },
        { english: "henequen", spanish: "Henequén" },
        { english: "peppermint", spanish: "Hierbabuena" },
        { english: "aromaticHerbs", spanish: "Hierbas aromáticas" },
        { english: "fig", spanish: "Higo" },
        { english: "jamaica", spanish: "Jamaica" },
        { english: "ginger", spanish: "Jenjibre" },
        { english: "jicama", spanish: "Jícama" },
        { english: "lettuce", spanish: "Lechuga" },
        { english: "lentil", spanish: "Lenteja" },
        { english: "lime", spanish: "Lima" },
        { english: "lemon", spanish: "Limón" },
        { english: "linseed", spanish: "Linaza" },
        { english: "litchi", spanish: "Litchi" },
        { english: "corn", spanish: "Maíz" },
        { english: "macadamia", spanish: "Macadamia" },
        { english: "maguey", spanish: "Maguey" },
        { english: "mamey", spanish: "Mamey" },
        { english: "tangerine", spanish: "Mandarina" },
        { english: "mango", spanish: "Mango" },
        { english: "mangosteen", spanish: "Mangostán" },
        { english: "chamomile", spanish: "Manzanilla" },
        { english: "appleTree", spanish: "Manzano" },
        { english: "passionFruit", spanish: "Maracuyá" },
        { english: "daisyFlower", spanish: "Margarita" },
        { english: "marjoram", spanish: "Mejorana" },
        { english: "melon", spanish: "Melón" },
        { english: "quince", spanish: "Membrillo" },
        { english: "mint", spanish: "Menta" },
        { english: "moringa", spanish: "Moringa" },
        { english: "turnip", spanish: "Nabo" },
        { english: "nanche", spanish: "Nanche" },
        { english: "napa", spanish: "Napa" },
        { english: "orange", spanish: "Naranja" },
        { english: "medlar", spanish: "Níspero" },
        { english: "nochebuena", spanish: "Nochebuena (planta)" },
        { english: "walnut", spanish: "Nogal" },
        { english: "noni", spanish: "Noni" },
        { english: "nopal", spanish: "Nopal" },
        { english: "oregano", spanish: "Orégano" },
        { english: "ornamental", spanish: "Ornamentales" },
        { english: "orchid", spanish: "Orquídea (planta)" },
        { english: "oilPalm", spanish: "Palma africana o de aceite" },
        { english: "potato", spanish: "Papa" },
        { english: "papaya", spanish: "Papaya" },
        { english: "grasslands", spanish: "Pastizales" },
        { english: "matGrass", spanish: "Pasto tapete (m2)" },
        { english: "pasturesAndMeadows", spanish: "Pastos y praderas" },
        { english: "cucumber", spanish: "Pepino" },
        { english: "pearTree", spanish: "Peral" },
        { english: "parsley", spanish: "Perejil" },
        { english: "peron", spanish: "Perón" },
        { english: "persimony", spanish: "Persimonio" },
        { english: "pepper", spanish: "Pimienta" },
        { english: "pineapple", spanish: "Piña" },
        { english: "pinion", spanish: "Piñón" },
        { english: "pistache", spanish: "Pistache" },
        { english: "pitaya", spanish: "Pitaya" },
        { english: "banana", spanish: "Plátano" },
        { english: "pore", spanish: "Poro" },
        { english: "radish", spanish: "Rábano" },
        { english: "rambutan", spanish: "Rambután" },
        { english: "romerito", spanish: "Romerito" },
        { english: "rosemary", spanish: "Romero" },
        { english: "aloe", spanish: "Sábila" },
        { english: "watermelon", spanish: "Sandía" },
        { english: "sorghum", spanish: "Sorgo" },
        { english: "soy", spanish: "Soya" },
        { english: "stevia", spanish: "Stevia" },
        { english: "tobacco", spanish: "Tabaco" },
        { english: "tamarind", spanish: "Tamarindo" },
        { english: "tejocote", spanish: "Tejocote" },
        { english: "tomato", spanish: "Tomate Rojo (jitomate)" },
        { english: "greenTomato", spanish: "Tomate Verde" },
        { english: "thyme", spanish: "Tomillo" },
        { english: "grapefruit", spanish: "Toronja (pomelo)" },
        { english: "clover", spanish: "Trébol" },
        { english: "wheat", spanish: "Trigo" },
        { english: "dutchTulip", spanish: "Tulipán holandés" },
        { english: "tuna", spanish: "Tuna" },
        { english: "grape", spanish: "Uva" },
        { english: "vanilla", spanish: "Vainilla" },
        { english: "purslane", spanish: "Verdolaga" },
        { english: "vine", spanish: "Vid" },
        { english: "cassavaFood", spanish: "Yuca alimenticia" },
        { english: "carrot", spanish: "Zanahoria" },
        { english: "sapodilla", spanish: "Zapote" },
        { english: "blackberry", spanish: "Zarzamora" },
        { english: "zempoalxochitl", spanish: "Zempoalxochitl" },
        { english: "fallow", spanish: "Barbecho" },
        { english: "other", spanish: "Otro" },
      ];

      let crop = crops.find((dataCrop) => dataCrop.english == cropEnglishName);

      if (crop) {
        return crop.spanish;
      } else {
        return "Sin cultivo";
      }
    },
  },
  components: { ControlPanel },
  computed: {
    ...mapState("session", ["permissions"]),
  },
};
</script>

<style></style>
